import {Project} from "./types";

// id: number;
// title: string;
// description: string;
// technologies: string[];
// github_link?: string;
// cover_img: Image ;
// skills?:string;
// media?:Image[];
// hosted?:boolean;
/*
paragraphs: [
    {
        paragraph_title: "first para",
        paragraph_content: "content"
    },
    {
        paragraph_title: "second para",
        paragraph_content: "content"
    },
    {
        paragraph_title: "third para",
        paragraph_content: "content"
    },

],
    */
export const project_list: Project[] = [{
//     id: 9,
//     title: "SeeFruit",
//     subtitle: "Hackathon project",
//     description: "A mobile platform using AI to determine food quality and price",
//     cover_img: {src: "/images/default_project.jpg", alt: "asd"},
//     technologies: ["React", "TypeScript"],
//     github_link: "https://github.com/omelettech/hackathon",
//     hosted_link: "https://seefruit.netlify.app",
//     media: [{
//         src: "/images/7/DB design.PNG", alt: "A diagram of a conceptual database design"
//     }, {src: '/images/7/carbon.png', alt: 'Snippet of a django method'}, {
//         src: "/images/7/postman.PNG", alt: "Screenshot of a postman request"
//     }, {src: "/images/7/swagger.PNG", alt: "Screenshot of a swagger API"}, {
//         src: "/images/7/adminPNG.PNG", alt: "Screenshot of django admin page"
//     }],
//     hosted: true
// }, {

    id: 8,
    title: "Pawductivity",
    subtitle: "Mobile Application",
    description: "A Gamified Habit and Journaling App feat. Interactive animated pet",
    technologies: ["NodeJs", "React native", "RIVE", "Postgres", "AWS", "MongoDb", "Typescript", "Javascript"],
    cover_img: {src: '/images/pawductivity/Captur2e.PNG', alt: 'screenshot of www.omelettedrawz.com'},
    media: [
        {src: "/images/pawductivity/Capture.PNG", alt: "A diagram of a conceptual database design"},
        {src: '/images/pawductivity/CAPTURE2.PNG', alt: 'Snippet of a django method'},
        {src: "/images/pawductivity/file-AoxVuCYx3oHwxmpEmCQcgS.png", alt: "Screenshot of a postman request"},
        {src: "/images/pawductivity/image.PNG", alt: "Screenshot of a swagger API"},
        {src: "/images/pawductivity/Captur2e.PNG", alt: "Screenshot of a swagger API"}

    ],
    paragraphs: [{
        paragraph_title: "Introduction",
        paragraph_content: `As part of my development journey, I had the opportunity to work on Pawductivity, a self-improvement habit and journaling app that incorporates gamification to enhance user engagement. This project was an exciting challenge, allowing me to apply my skills in mobile app development, backend services, and user experience design while focusing on promoting productivity and mental health.`
    },

        {
            paragraph_title: "Overview",
            paragraph_content: "Pawductivity is designed for individuals who struggle with self-motivation and self-control. The core idea revolves around a virtual pet that users can interact with by completing goals. By integrating features like habit tracking, journaling, focus timers, and sleep tracking, the app encourages users to build positive habits while having fun.\n" + "\n" + "Key Features:\n" + "\n" + "✅Habit Tracking: Users can set and track their progress on habits.\n" + "\n" + "✅Journaling: Allows users to log daily thoughts and moods.\n" + "\n" + "✅Calendar Integration: Users can schedule tasks and receive reminders.\n" + "\n" + "✅Focus Timers: Helps users minimize distractions.\n" + "\n" + "✅Sleep Tracking: Enables users to monitor their sleep schedules.\n" + "\n" + "✅Gamification: Completing real-life tasks rewards users with pet interactions, customization, and level-ups.\n" + "\n" + "✅Admin Controls: Includes announcement publishing, role management, and pet customization."

        }, {
            paragraph_title: "Features", paragraph_content: "Tech Stack & Tools\n" +

                "✅Frontend: React Native for cross-platform mobile development.\n" +

                "✅Backend: Node.js with Express and GraphQL for efficient API management.\n" +

                "✅Database: MongoDB for storing user data, habits, and pet states.\n" +

                "✅Authentication: Firebase for secure user authentication.\n" +

                "✅Notifications & Reminders: Implemented using Firebase Cloud Messaging (FCM)."

        },

        {
            paragraph_title: "Outcome & Learnings",
            paragraph_content: "This project significantly strengthened my skills in full-stack development, API design, and UX/UI design. Key takeaways:\n" + "\n" + "Gamification can drive user engagement without being overwhelming.\n" + "\n" + "Efficient database design and caching improve app performance.\n" + "\n" + "User feedback loops are crucial for refining features."
        }, {
            paragraph_title: "Takeaways",
            paragraph_content: "Working on Pawductivity was an enriching experience that combined technology, psychology, and design. This project exemplifies my ability to build meaningful applications that positively impact users' lives. If you're interested in similar projects or collaboration, feel free to connect with me!"
        },

    ],
    hosted: false,
    status: "WIP",
}, {

    id: 7,
    title: "Over Engineered Ecommerce App",
    subtitle: "Django REST Api",
    description: "An authenticated Monolithic python backend with an MVC architecture",
    technologies: ["Django", "Python", "DjangoRestFramework", "Django All-Auth", "JWT", "SQL"],
    cover_img: {src: '/images/7/DB design.PNG', alt: 'screenshot of www.omelettedrawz.com'},
    media: [{
        src: "/images/7/DB design.PNG", alt: "A diagram of a conceptual database design"
    }, {src: '/images/7/carbon.png', alt: 'Snippet of a django method'}, {
        src: "/images/7/postman.PNG", alt: "Screenshot of a postman request"
    }, {src: "/images/7/swagger.PNG", alt: "Screenshot of a swagger API"}, {
        src: "/images/7/adminPNG.PNG", alt: "Screenshot of django admin page"
    },


    ],
    paragraphs: [{
        paragraph_title: "In short",
        paragraph_content: "A backend ecommerce REST API application built with Django and django-all-auth"
    },

        {
            paragraph_title: "Overview",
            paragraph_content: "✅  Custom controller actions that performs server side actions and modifies the database to best practice \n " + "✅  Curated serializers that optimizes payload weight and also sends custom fields to client side \n " + "✅  Fully authenticated with JWT, CORS and in built Django auth middleware \n " + "✅  Basic signal implementation: Server automatically performs actions based on specific events"

        }, {
            paragraph_title: "Features",
            paragraph_content: "✅ User Authentication & Authorization\n" + "Secure JWT-based authentication with Django REST Framework (DRF)\n" + "Role-based access control (admin, customer, seller)\n" + "Social authentication (Google, Facebook) (optional)\n" + "\n" +

                "✅ Product & Category Management\n" + "CRUD operations for products and categories\n" + "Product variants (sizes, colors, etc.)\n" + "Image support with Django’s media storage\n" + "\n" +

                "✅ Cart & Checkout System\n" + "Add/update/remove items from the cart\n" + "Support for discounts and coupons\n" + "Secure order placement and tracking"

        },

        {
            paragraph_title: "Technical Stack",
            paragraph_content: "Backend: Django, Django REST Framework\n" + "Database: MySQL\n" + "Authentication: JWT (Simple JWT)\n"
        }, {
            paragraph_title: "Takeaways",
            paragraph_content: "This Django e-commerce backend provides a secure, scalable, and API-driven solution " + "for online stores. With authentication, product management, cart functionality, and payment integration, " + "it ensures a smooth shopping experience. Built with Django REST Framework and PostgreSQL, it’s optimized " + "for performance and easy deployment"
        },

    ],
    hosted: false,
    status: "WIP",
}, {
    id: 0,
    title: "Omelettedrawz",
    subtitle: "Single Page Application",
    description: "A full-stack Ecommerce Web App with API integration for selling and showcasing art products ",
    technologies: ["React", "JavaScript", "CSS", "Axios"],
    cover_img: {src: '/images/0/omelettedrawz.PNG', alt: 'screenshot of www.omelettedrawz.com'},
    media: [{src: '/images/0/omelettedrawz.PNG', alt: 'image of an art website'}, {
        src: "/images/0/oml82.PNG", alt: "image of website: omelettedrawz.com"
    },

    ],
    paragraphs: [{
        paragraph_title: "In short",
        paragraph_content: "A full stack Web application for showcasing and selling art. What I learned:<br/> " + "**React, Axios, API integration, Front end design, Django, E-commerce system design, User auth**"
    },

        {
            paragraph_title: "Overview",
            paragraph_content: "" + `\"Omelettedrawz\" is a **full-stack** web application designed as a personal art platform for ` + "showcasing and selling art. It includes an e-commerce storefront that facilitates art sales, " + "by showcasing products made by the artist. Some products are offered by a **third party** supplier " + "for which I had to integrate their **API** to display products along with the products stored" + "in the **database** "
        }, {
            paragraph_title: "Features",
            paragraph_content: "The platform includes an intuitive e-commerce system for browsing and purchasing " + "artwork, implemented using a dynamic React frontend. The shop features a cart system with " + "real-time updates, checkout functionality, and responsive design to ensure accessibility " + "across devices. Additional features include an artist commission page that highlights the pricing " + "and services of the artist offering custom artworks, as well as a personalized gallery that allows" + " for easy updates. The backend was" + " built with Django and Python,and it manages **user authentication**, **order processing**, and **content " + "management** efficiently." + "PS. The shop features are still not in production as enough content/products is still " + "lacking from the application/website, it is more of a"
        }, {
            paragraph_title: "Challenges",
            paragraph_content: "Developing Omelettedrawz, I faced several challenges, particularly in integrating" + " the React frontend with the Django backend and also **Printful's API** for seamless data flow." + " Ensuring the platform" + "o maintained performance while managing complex state updates and handling asynchronous operations " + "was difficult. Another major hurdle was implementing a secure and smooth e-cmmerce experience," + " which required thorough testing of payment flows and user data handling. Additionally, creating " + "a visually cohesive design that aligned with the artist’s aesthetic while remaining functional was" + " a fun creative challenge posed on myself, the artist+ the developer."
        }, {
            paragraph_title: "Technical Stack",
            paragraph_content: "The project uses a combination of modern and powerful technologies. The frontend is" + " developed using React and styled with CSS, providing a dynamic and engaging user interface." + " JavaScript is used extensively for handling interactivity. The backend is powered by Django, " + "a robust Python framework that manages database operations and server-side logic. Together, " + "this stack ensures a scalable and maintainable application architecture."
        }, {
            paragraph_title: "Takeaways",
            paragraph_content: "Building \"Omelettedrawz\" has deepened my understanding of full-stack development," + " especially in harmonizing frontend and backend technologies. I learned the importance of " + "efficient state management in React and best practices for securing web applications. " + "This experience has also enhanced my skills in e-commerce development, from handling payments to " + "optimizing the user journey. Overall, this project showcases my ability to deliver a complex," + " real-world solution while remaining adaptable and focused on user experience."
        },

    ],
    hosted: true,
    status: "WIP",
    hosted_link: "https://omelettedrawz.com"

}, {
    id: 1,
    title: "Mobi Fintech",
    subtitle: "Mobile Application",
    description: "A mobile stock brokerage application demo.",
    cover_img: {src: "/images/fintech/4.gif", alt: "asd"},
    technologies: ["React Native", "Django", "DjangoREST", "Javascript", "Python"],
    media: [{src: '/images/fintech/4.gif', alt: 'screenshot of mobile application'}, {
        src: '/images/fintech/2.PNG', alt: 'screenshot of mobile application'
    }, {src: '/images/fintech/3.PNG', alt: 'screenshot of mobile application'}, {
        src: '/images/fintech/1.PNG', alt: 'screenshot of mobile application'
    }],
    status: "WIP",
    paragraphs: [{
        paragraph_title: "Overview",
        paragraph_content: "A mobile application built with **React Native** and DjangoREST framework that is" + "a stock brokerage app which can handle buying and selling of stocks. Currently no cash transaction" + "is integrated, However this app can dynamically take *API* requests from popular stock API. Most " + "functionalities are still under construction however the main focus for this was a beautiful UI/UX " + "while maintaining good enough features"
    }, {
        paragraph_title: "Front-End and Back-End",
        paragraph_content: "My primary reason for starting to build this app was a nice UI since all other stock" + "apps had clunky and noisy UI. This app features a **Stock Ticker Tape** which is a **queue** of incoming" + "stock updates from a **live** api. It has a market page, which lists all of the stocks available for " + "buying/selling with a search functionality. The portfolio page is not completed however I have plans" + "to make it as easy to read and understand with smart design choices. The **backend** is a Django Rest" + "framework with custom system commands, the endpoints communicate with Finazon, a free stock api. "
    }, {
        paragraph_title: "Future Updates",
        paragraph_content: "Since this app is a work in progress, I plan to first complete and publish it with" + "the most important and core functionalities, mainly stock buying/selling with a given market API." + "In the far future I see myself working on its edges and animations that enhances the entire look of " + "the application. This is a cross platform app so I would also like to make the frontend completely" + "fool proof for multiple devices"
    },

    ],
},

    {
        id: 2,

        title: "DoraTheExplorer",
        subtitle: "Local Web App",
        description: "A local software to manage my library of media with tags",
        cover_img: {src: "/images/doragif.gif", alt: "gif of DoraTheExplorer, a media managing application"},
        technologies: ["React", "Javascript", "Python", "Django", "SQLite"],
        media: [{src: '/images/doragif.gif', alt: 'gif of DoraTheExplorer, a media managing application'}, {
            src: '/images/spoodernam.PNG',
            alt: 'screenshot of DoraTheExplorer with spiderman, a media managing application'
        }, {
            src: '/images/pink.png',
            alt: 'screenshot of DoraTheExplorer with pink panther, a media managing application'
        },],
        paragraphs: [{
            paragraph_title: "Overview",
            paragraph_content: "\"DoraTheExplorer\" is a local web application developed to organize and manage a" + " personal library of media," + "It features a **flexible tagging system**, allowing users to categorize and search for media items " + "efficiently using customized **tags** and **actors**."
        }, {
            paragraph_title: "Features",
            paragraph_content: "The app is built with a React frontend for a dynamic user experience and a " + "**Django backend** with **SQLlite** to handle data management." + " It uses SQLite for local data storage, ensuring lightweight and fast performance." + " The intuitive interface makes organizing media simple." + "It has a tag and actor functionality which can be used to sort/search through a library of media" + "really quickly"

        }, {
            paragraph_title: "Takeaways",
            paragraph_content: "Future updates will introduce enhanced search capabilities and better tag" + " recommendations. Building this project honed my skills in creating scalable," + " user-friendly interfaces and taught me the value of **efficient database management** in " + "local applications."
        },

        ],
        status: "Paused",

    }, {
        id: 3,
        title: "this.super()",
        subtitle: "static website",
        description: "My previous personal website",
        cover_img: {src: "/images/1.PNG", alt: "asd"},
        technologies: ["HTML", "CSS", "Javascript"],
        media: [{src: '/images/1.PNG', alt: 'Screenshot of a website'}, {
            src: '/images/2.PNG', alt: 'screenshot of a website'
        },],

    }, {
        id: 4,
        title: "Connect4 AI",
        subtitle: "CLI AI Java game",
        description: "A CLI based connect 4 game with MINMAX ai player as the opponent",
        cover_img: {src: "/images/connect4.gif", alt: "Gif of a command line connect 4 game"},
        technologies: ["Java"],
        media: [{src: '/images/connect4.gif', alt: 'Gif of a command line connect 4 game'},],
        paragraphs: [{
            paragraph_title: "Overview",
            paragraph_content: "A command-line-based Connect 4 game developed in Java, featuring a sophisticated " + "AI opponent that uses the **Minimax algorithm**." + " The **AI** is capable of evaluating future moves along with a difficulty setting"

        }, {
            paragraph_title: "AI Implementation:\n",
            paragraph_content: "The Minimax algorithm evaluates potential moves by simulating different game outcomes," + " optimizing the AI's strategy to either block the opponent or win whenever possible." + " This project demonstrates the use of **recursive algorithms** and emphasizes the importance " + "of **heuristic** functions that lets the AI system look ahead and choose the best possible option."
        }, {
            paragraph_title: "Takeaways",
            paragraph_content: "Potential improvements include optimizing the AI for faster response times" + " . Developing this game honed my understanding of **AI** concepts in game theory and reinforced " + "the importance of efficiency in **algorithm** design."
        },

        ],
    }, {
        id: 5,
        title: "Tank Game",
        subtitle: "Game with Pygame",
        description: "A game made with pygame and collision logic",
        cover_img: {src: "/images/tank_top.jpg", alt: "asd"},
        technologies: ["Python", "Pygame"],
        media: [{src: '/images/tank_top.gif', alt: 'Gif of a tank shooting game'},],

    }, {
        id: 6,
        title: "Tic tac toe",
        subtitle: "Game with Pygame",
        description: "A game made with pygame with GUI",
        cover_img: {src: "/images/default_project.jpg", alt: "asd"},
        technologies: ["Python", "Pygame"],
        github_link: "https://example.com/project-two",
    },];