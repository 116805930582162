import React, {ReactNode, useState} from 'react';
import {Project} from "./types";
import {Link, useParams} from "react-router-dom";
import {project_list as projects} from "./project_list";
import PixelButton from "../../components/PixelButton/PixelButton";

interface ProjectProps {
    children?: ReactNode[]
}

const ProjectPage = ({children}: ProjectProps) => {
    const [selectedImg, setSelectedImg] = useState<number>(0);
    const {projectId} = useParams<{ projectId: string }>();
    const project = projects.find((p) => p.id === Number(projectId));


    if (!project) {
        return <p>Project not found</p>;
    }


    const nextSlide = () => {
        // @ts-ignore
        setSelectedImg((selectedImg + 1) % project.media.length);
    };

    const prevSlide = () => {
        // @ts-ignore
        setSelectedImg(selectedImg === 0 ? project.media.length - 1 : selectedImg - 1)
    };

    return (
        <div>
            <div className="window98-title-bar">
                <span className="window98-title">{project.title + ".exe"}</span>
                <div className="window98-buttons">
                    <Link to={"/portfolio"} className="window98-button">_</Link>
                    <Link to={`/project/${project.id}`} className="window98-button">□</Link>
                    <Link to={"/portfolio"} className="window98-button">×</Link>
                </div>
            </div>

            {project.media && project.media.length > 1 ?
                <div className={"slideshow"}>
                    {project.media && <img src={project.media[selectedImg].src} alt={project.media[selectedImg].alt}/>}
                    <div className={"controls"}>
                        <button className="prev" onClick={() => nextSlide()}>&#10094;</button>
                        <button className="next" onClick={() => prevSlide()}>&#10095;</button>
                    </div>
                </div> :
                <div className={"slideshow"}><img src={project.cover_img.src} alt={project.cover_img.alt}/></div>
            }

            <div className={"content"}>
                <br/>
                {project.hosted &&
                    <PixelButton text={"Visit Site"} onclick={() => window.open(project.hosted_link, "_blank")}/>}
                <h1>{project.title} </h1>
                <h2>{project.description}</h2>
                {project.paragraphs?.map((para, index) =>
                    <div key={index} className={"paragraph-content"}>
                        <h3>{para.paragraph_title}</h3>
                        <p>{para.paragraph_content.split('\n').map((text, i) => {
                                return <>
                                    {text}
                                    <br/>
                                </>
                            }
                        )
                        }</p>

                    </div>
                )}

            </div>
        </div>
    );

};

export default ProjectPage;