import React from 'react';
import './TechIcons.css';
import { SiDjango } from "react-icons/si";
import {RiJavascriptFill} from "react-icons/ri";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {faCoffee,faDatabase} from  "@fortawesome/free-solid-svg-icons"
import {
    faReact,
    faNodeJs,
    faCss3,
    faHtml5,
    faGithub,
    faPython,
    faJava,
    faJs,
    faAws
} from "@fortawesome/free-brands-svg-icons";
// import {} from "@fortawesome/fontawesome-svg-core"

import typescript from "../../assets/img/typescript.svg"
interface TechIconsProps {
    techs:string[]
}



const iconMapping: any = {
    react: <FontAwesomeIcon icon={faReact}/>,
    typescript: <img src={typescript} alt={"Typescript logo"}/> , // Using JS icon for TypeScript
    django:<SiDjango/>,
    nodejs: <FontAwesomeIcon icon={faNodeJs}/>,
    css: <FontAwesomeIcon icon={faCss3}/>,
    html: <FontAwesomeIcon icon={faHtml5}/>,
    github: <FontAwesomeIcon icon={faGithub}/>, // Optional: add other icons as needed
    python:<FontAwesomeIcon icon={faPython}/>,
    javascript:<FontAwesomeIcon icon={faJs}/>,
    java:<FontAwesomeIcon icon={faJava}/>,
    "react native":<FontAwesomeIcon icon={faReact}/>,
    sqlite:<FontAwesomeIcon icon={faDatabase}/>,
    aws:<FontAwesomeIcon icon={faAws}/>,
};

const TechIcons = ({techs}:TechIconsProps) => {
    return (
        <div className="tech-icons-container">
            {techs.map((tech, index) => {
                let IconComponent = <span>?</span>

                const techName = tech.toLowerCase();
                if(techName.includes("django")){
                    IconComponent = iconMapping["django"]
                }else {
                    IconComponent = iconMapping[techName];
                }
                return (
                    <div key={index} className="tech-icon">
                        <div className="icon-container">
                            {IconComponent || <p>{tech.slice(0,5)}</p>} {/* Fallback if icon is not found */}
                        </div>
                        <div className="tooltip">
                            <span className="tooltiptext">{tech}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default TechIcons;