import React, {useState} from 'react';
import "./Console.css"
import {useNavigate} from "react-router-dom";

import static_commands, {Command} from "./Commands";

const rootFolderDir = 'C:\\Users\\YoursTruly> ';
const EMPTY_STRING = ""
const Console: React.FC = () => {
    const navigate = useNavigate()
    const [commandHistory, setCommandHistory] = useState<string[]>([]);
    const [currentCommand, setCurrentCommand] = useState<string>(EMPTY_STRING);
    const commands: Command[] = [
        ...static_commands,
        {
            command: 'about',
            process: () => redirectToPage('/about', '*~Teleported to about~*'),
            description: 'redirect to about section',
        },
        {
            command: 'contact',
            process: () => redirectToPage('/contact', '*~Teleported to contacts~*'),
            description: 'Redirect to contact section',
        },
        {
            command: 'portfolio',
            process: () => (window.location.href = '/projects'),
            description: 'Redirect to portfolio page',
        },
        {
            command: 'resume',
            process: () => downloadFile('resume.pdf', "( ^-^)_旦 <(here are the documents you requested)"),
            description: 'Download my resume',
        },
        {
            command: 'linkedin',
            process: () =>
                openLink(
                    'https://www.linkedin.com/in/awsaf-fida-mahmud-bbb115211',
                    '║▌║█║▌LINKEDIN│║▌║▌█',
                    "Checkout my LinkedIn. Be sure to come back :D"
                ),
            description: 'Redirect to my LinkedIn profile',
        },
        {
            command: 'github',
            process: () =>
                openLink(
                    'https://www.github.com/omelettech',
                    '▬▬ι═══════ﺤ  GITHUB  -═══════ι▬▬',
                    "Checkout my GitHub. Be sure to come back :D"
                ),
            description: 'Redirect to my GitHub profile',
        },
        {
            command: 'clear',
            process: () =>
                setCommandHistory([]),
            description: 'you know what it does :D',
        },
    ];
    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {

            event.preventDefault();
            processCommand(currentCommand);
            setCurrentCommand(EMPTY_STRING);
        }
        // if ((event.key === "Backspace" || event.key==="Delete") && currentCommand.length <= rootFolderDir.length) {
        //     event.preventDefault();
        // }
        // if(!currentCommand.startsWith(rootFolderDir)){
        //     setCurrentCommand(EMPTY_STRING)
        // }
    };

    const appendToHistory = (message: string) => {
        setCommandHistory((prevHistory) => [...prevHistory, message]);
    };

    const redirectToPage = (destination: string, message: string) => {
        navigate(destination)
        appendToHistory(`${rootFolderDir} ${message}`);
    };

    const downloadFile = (filePath: string, message: string) => {
        appendToHistory(`${rootFolderDir} ${message}`);
        window.open(filePath, '_blank');
    };

    const openLink = (url: string, linkText: string, message: string) => {
        appendToHistory(`${rootFolderDir} ${message}`);
        window.open(url, '_blank');
    };

    const processCommand = (command: string) => {
        appendToHistory(`${rootFolderDir} ${command}`)
        command = command.trim().toLowerCase()
        // command = command.slice(rootFolderDir.length).trim()
        if (command===""){
            return
        }

        const foundCommand = commands.find((cmd) => cmd.command === command);

        if (foundCommand) {
            foundCommand.process();
        } else if (command === 'help') {
            let helpMessage = "HELP\ncommands \t--->\t desc\n"
            helpMessage += commands.map((cmd) => `${cmd.command} ----> ${cmd.description}`).join('\n');
            appendToHistory(`${rootFolderDir} ${helpMessage}`);
        } else {
            appendToHistory(
                `'${command}' is not recognized as an internal or external command,\ntype 'help' for more information`
            );
        }
    };

    return (
        <div className={"console-command-container"}>

            <div id="commandHistory">
                <h2 className={"console-header"}>Awsaf's Dev Portfolio [Version 0.0.9045.5011]</h2>
                (c) Awsaf Fida Mahmud. All rights reserved.
                <br/>
                <br/>

                {commandHistory.map((entry, index) => (
                    <pre className={"command-history"} key={index}>

                        {entry}
                        <br/>
                        <br/>

                    </pre>

                ))}
            </div>
            <div className={"consoleContainer"}>
                <div id={"consoleLabel"}>C:\Users\YoursTruly&gt;</div>
                <textarea
                    autoFocus={true}
                    id="console"
                    value={currentCommand}
                    onChange={(e) => setCurrentCommand(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Type a command and press Enter..."
                />
            </div>
        </div>
    );
};

export default Console;
