import React from 'react';
import {Project} from "./types"
import "./ProjectCard.css"
import {Link, useNavigate} from "react-router-dom";
import TechIcons from "../../components/TechIcons/TechIcons";
interface ProjectCardProps {
    project: Project;
    onClick?:any;
}
const ProjectCard = ({project,onClick}: ProjectCardProps) => {
    const navigate = useNavigate()

    return (
        <div className="window98-card">
            <div className="window98-title-bar">
                <span className="window98-title">{project.title + ".exe"}</span>
                <div className="window98-buttons">
                    <span className="window98-button">?</span>
                    <Link to={`/project/${project.id}`} className="window98-button">□</Link>
                    {/*<span className="window98-button">×</span>*/}
                    {project.hosted && project.hosted_link && <Link to={project.hosted_link} className={"window98-button"}>↗</Link>}
                </div>
            </div>



            <div className="window98-content">
                <img className="cover-img" src={project.cover_img.src} alt={project.cover_img.alt}/>

                <div style={{width:"100%", cursor:"pointer"}} className={"window"} onClick={onClick}>
                    <h1>{project.title}</h1>
                    <h2>{project.subtitle}</h2>
                    <h3>{project.description}</h3>
                    <div className={"tech-stack"}>
                        {/*{project.technologies.map((tech, id) => (*/}
                        {/*    <div key={id}>{tech}</div>*/}
                        {/*))}*/}
                        {/*<div>Tech stack:</div>*/}
                        <TechIcons techs={project.technologies}></TechIcons>
                    </div>
                    {project.hosted &&
                        <div style={{textAlign:"right",color:"red",fontSize:"1.5rem"}} className={"blink"}>
                            🔴 Live
                        </div>
                    }

                </div>

            </div>


        </div>

    );
};

export default ProjectCard;